import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from './prompt.service';
import { PromptButtonGroup, PromptResult, PromptType } from '../models/enums';

@Injectable({
    providedIn: 'root'
})
export class AngularUpdateService {
    shouldCheckForUpdates = true;
    checkingSubscriptionObject: any = null;
    isUpdateAvailable = false;

    constructor(
        private appRef: ApplicationRef, 
        private swUpdate: SwUpdate, 
        private toastService: ToastrService,
        private promptService: PromptService,
    ) {
        if (this.swUpdate.isEnabled) {
            this.checkForUpdates();
        }
    }

    subscribeToUpdates() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((event) => {
                if (event.type == 'VERSION_DETECTED') {
                    this.changeUpdateCheckingStatus(false);
                }
            
                if(event.type == 'VERSION_READY') {
                    if(event.latestVersion.appData) {
                        const appData = event.latestVersion.appData as any;
    
                        // Showing prompt for Non-Critical Updates.
                        if(appData?.isCriticalUpdate) this.updateApplicationToLatestVersion();
                        else this.showUpdatePrompt();
                    } else {
                        this.showUpdatePrompt();
                    }
                }
    
                if(event.type === 'VERSION_INSTALLATION_FAILED') {
                    console.error('Unrecoverable state reached, reloading page');
                    this.reloadApp();
                }
            });
        }
    }

    showUpdatePrompt() {
        this.promptService.showPrompt(PromptType.Question,
            'Update Available',
            'A new update is available! Make sure you are not in middle of some work and changes are saved. Click OK to update.',
            PromptButtonGroup.OkCancel, (promptResult) => {
            if (promptResult === PromptResult.Ok) {
                this.updateApplicationToLatestVersion();
            } else {
                this.toastService.warning('Update Cancelled!');
                this.promptService.dialog.closeAll();
                this.isUpdateAvailable = true;
            }
        });
    }
    
    updateApplicationToLatestVersion() {
        this.toastService.info('Updating... Application will reload automatically once updated!', 'Updating');
        this.swUpdate.activateUpdate().then(() => this.reloadApp()).catch(error => {
            console.error('Error while updating the application. Error=>', error);
            this.toastService.error('There is some error while updating... Please refresh the application once!', 'Update Failed');
        }).finally(() => {
            this.changeUpdateCheckingStatus(true);
        });
    }

    reloadApp() {
        document.location.reload();
    }

    changeUpdateCheckingStatus(status: boolean) {
        if (status !== this.shouldCheckForUpdates) {
            this.shouldCheckForUpdates = status;
            if (this.shouldCheckForUpdates === true) {
                this.checkForUpdates();
            } else {
                this.checkingSubscriptionObject.unsubscribe();
            }
        }
    }

    checkForUpdates() {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everyXTime$ = interval(5 * 60 * 1000); // every 5 minutes
        const everyXTimeOnceAppIsStable$ = concat(appIsStable$, everyXTime$);

        if(this.checkingSubscriptionObject) this.checkingSubscriptionObject.unsubscribe();

        this.checkingSubscriptionObject = everyXTimeOnceAppIsStable$.subscribe({
            next: () => {
                this.swUpdate.checkForUpdate()
                    .then(() => {})
                    .catch(error => console.error('Error while checking for updates:', error));
            },
            error: (err) => console.error('Error in update check subscription:', err)
        });
    }
}

