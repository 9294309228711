import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularUpdateService } from './shared/services/angular-update.service';

@Component({
  selector: 'crm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = environment?.node_env || 'Production';

  constructor(private updateService: AngularUpdateService) {
    this.updateService.subscribeToUpdates();
  }
}
